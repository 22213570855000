import React from 'react';
import classes from './error.module.scss';
import Layout from 'components/Layout';
import PageNotFoundSVG from 'public/assets/images/404.svg';
import SKButton from 'components/UI/SKButton';
import { HOME_PAGE } from 'constants/route.const';
import { useRouter } from 'next/router';

export default function PageNotFound(props) {
  const router = useRouter();
  return (
    <Layout>
      <div className={classes.PageNotFound}>
        <PageNotFoundSVG />
        <h1>Page Not Found</h1>
        <p>The Page you are looking for cannot be found.</p>
        <SKButton onClick={() => router.push(HOME_PAGE)}>
          Back to Homepage
        </SKButton>
      </div>
    </Layout>
  );
}
